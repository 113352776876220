<template>
  <div class="pb-[7.8vh] pt-[86px]">
    <navigation-bar />

    <router-view v-slot="{ Component, route }">
      <transition :name="route.meta.transition">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script>
export default {};
</script>
