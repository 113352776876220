import { createWebHistory, createRouter } from "vue-router";

import HomeIndex from "@/views/pages/index.vue";
import AppLogin from "@/views/pages/app_login.vue";

import AccountPage from "@/views/account/index.vue";
import DevicesPage from "@/views/account/_devices.vue";
import PowerDetailPage from "@/views/account/_power_detail.vue";
import UserProfilePage from "@/views/account/_user_profile.vue";
import DeviceDashboard from "@/views/account/_device_dashboard.vue";
import ClimateSettingsPage from "@/views/account/_climate_settings.vue";
import StatusReportPage from "@/views/account/_status_report.vue";

import Error404 from "@/views/pages/error_404.vue";

const routes = [
  { path: "/", component: HomeIndex, name: "root_path" },

  {
    path: "/account",
    component: AccountPage,
    name: "account_path",
    children: [
      { path: "devices", component: DevicesPage, name: "devices_path" },
      { path: "devices/power/:device_serial", component: PowerDetailPage, name: "power_detail_path" },
      { path: "devices/climate/:device_serial", component: ClimateSettingsPage, name: "climate_settings_path" },
      { path: "devices/status_report/:device_serial", component: StatusReportPage, name: "status_report_path" },
      { path: "devices/dashboard/:device_serial", component: DeviceDashboard, name: "device_path" },
      { path: "user_profile", component: UserProfilePage, name: "user_profile_path" },
    ],
  },

  { path: "/app_login/:role", component: AppLogin, name: "app_login_path" },
  { path: "/404", component: Error404 },

  { path: "/:catchAll(.*)", redirect: "/404" },
];

const router = createRouter({
  history: createWebHistory(`/${I18n.prefix}`),
  routes, // short for `routes: routes`
});

router.afterEach((to, from) => {
  // console.log("Route from", from.path, "to", to.path);

  if (to.path === from.path || (from.path.includes("/account/devices") && to.path.includes("/account/user")) || (from.path.includes("/account/user") && to.path.includes("/account/devices"))) {
    return;
  }
  if (to.path === "/" || (to.name === "devices_path" && from.name === "device_path")) {
    to.meta.transition = "slide-right";
  } else {
    to.meta.transition = "slide-left";
  }
});

export default router;
