<template>
  <div
    v-if="device_online && (device_boot_status === 0 || device_boot_status === 2)"
    style="border-top-left-radius: 55px 148px; border-top-right-radius: 55px 148px"
    class="fixed inset-x-0 -bottom-28 left-0 right-0 m-auto flex h-64 w-32 justify-center bg-white px-2 pb-4 pt-2 shadow-2xl shadow-black">
    <button v-if="device_boot_status === 0" type="button" @click="onclickStart" class="absolute outline-none active:shadow-2xl">
      <img src="@/assets/images/start_btn.png" class="h-16 w-16" />
    </button>

    <div v-if="device_boot_status === 2" class="absolute flex flex-col items-center">
      <svg class="h-12 w-12 animate-spin text-primary-blue" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
      <span class="text-sm text-second-black">Booting up ...</span>
    </div>
  </div>
</template>
<script>
export default {
  inject: ["device_online", "device_boot_status"],
  props: {
    onclickStart: {
      type: Function,
      default: null,
    },
  },
};
</script>
