import { createApp } from "vue";
const app = createApp(Layout);
import Router from "@/routes.js";
import Layout from "@/views/shared/layout.vue";
import Axios from "axios";

// Register global components and partials
import AppBottomLogo from "@/views/shared/_app_bottom_logo.vue";
import LoadingIcon from "@/components/LoadingIcon.vue";
import NavigationBar from "@/components/NavigationBar.vue";
import ModalBox from "@/components/ModalBox.vue";
import Pannel from "@/components/Pannel.vue";
import Passengers from "@/components/Passengers.vue";

// Pannels
import ClimatePannel from "@/components/pannels/ClimatePannel.vue";
import SpeedPannel from "@/components/pannels/SpeedPannel.vue";
import SpeedPowerPannel from "@/components/pannels/SpeedPowerPannel.vue";
import StatusPannel from "@/components/pannels/StatusPannel.vue";
import RoutePannel from "@/components/pannels/RoutePannel.vue";
import DoorsPannel from "@/components/pannels/DoorsPannel.vue";
import PassengerStatPannel from "@/components/pannels/PassengerStatPannel.vue";
import PowerDetailPannel from "@/components/pannels/PowerDetailPannel.vue";
import PowerPannel from "@/components/pannels/PowerPannel.vue";
import ClimateSettingPannel from "@/components/pannels/ClimateSettingPannel.vue";

// Widgets
import BatteryStatusWidget from "@/components/widgets/BatteryStatusWidget.vue";
import DeviceItem from "@/components/widgets/DeviceItem.vue";
import H2wayIndicator from "@/components/widgets/H2wayIndicator.vue";
import StartButton from "@/components/widgets/StartButton.vue";
import StatusWidget from "@/components/widgets/StatusWidget.vue";
import ClimateWidget from "@/components/widgets/ClimateWidget.vue";

app.component("app-bottom-logo", AppBottomLogo);
app.component("loading-icon", LoadingIcon);
app.component("navigation-bar", NavigationBar);
app.component("modal-box", ModalBox);
app.component("pannel", Pannel);
app.component("passengers", Passengers);
app.component("ClimatePannel", ClimatePannel);
app.component("SpeedPannel", SpeedPannel);
app.component("SpeedPowerPannel", SpeedPowerPannel);
app.component("StatusPannel", StatusPannel);
app.component("RoutePannel", RoutePannel);
app.component("DoorsPannel", DoorsPannel);
app.component("PassengerStatPannel", PassengerStatPannel);
app.component("PowerDetailPannel", PowerDetailPannel);
app.component("PowerPannel", PowerPannel);
app.component("ClimateSettingPannel", ClimateSettingPannel);
app.component("BatteryStatusWidget", BatteryStatusWidget);
app.component("DeviceItem", DeviceItem);
app.component("H2wayIndicator", H2wayIndicator);
app.component("StartButton", StartButton);
app.component("StatusWidget", StatusWidget);
app.component("ClimateWidget", ClimateWidget);

// Form animations + Axios
import { createApi } from "@/plugins/api";
const Api = createApi({ handler: Axios, namespace: "" });

// Pinia + Axios setup
import { createPinia } from "pinia";
const Pinia = createPinia();
Pinia.use(({ store }) => {
  store.axios = Axios;
});

import { createI18n } from "vue-i18n";
const I18n = createI18n({ locale: "current", messages: translations });

app.config.unwrapInjectedRef = true;
app.use(Router).use(Pinia).use(I18n).use(Api).mount("#app");
