<template>
  <div class="">
    <div class="container mx-auto">
      <router-view v-slot="{ Component, route }">
        <transition :name="route.meta.transition">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
    <ul role="tablist" class="tabs-component-tabs">
      <li class="tabs-component-tab" :class="{ 'is-inactive': active_tab === 'user_profile' }" role="presentation">
        <router-link :to="{ name: 'devices_path' }" class="tabs-component-tab-a">
          <img src="@/assets/images/train_tab_icon.png" class="h-6" />
          <span>Trains</span>
        </router-link>
      </li>
      <li class="tabs-component-tab" :class="{ 'is-inactive': active_tab === 'devices' }" role="presentation">
        <router-link :to="{ name: 'user_profile_path' }" class="tabs-component-tab-a">
          <img src="@/assets/images/user_tab_icon.png" class="h-6" />
          <span>User</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { DeviceStore } from "@/stores/device_store";
export default {
  components: {},

  setup() {
    const store = DeviceStore();

    return { store };
  },
  data() {
    return {
      active_tab: "devices",
    };
  },

  watch: {
    $route(to, from) {
      if (to.path.includes("/account/devices")) {
        this.active_tab = "devices";

        if (from.path.includes("/account/devices/") && to.path === "/account/devices") {
          this.store.device = {};
          this.store.user = {};
        }
      } else {
        this.active_tab = "user_profile";
      }
    },
  },

  created() {
    console.log("account/index.vue created");
    if (this.$route.path.includes("/account/devices")) {
      this.active_tab = "devices";
    } else {
      this.active_tab = "user_profile";
    }
  },
};
</script>
